$pink: rgb(253, 152, 169);

body {
    background-color: #cfcfcf;
}

.lilita-one-regular {
    font-family: "Lilita One", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.links {
    text-align: center;
    max-width: 600px;
    margin: auto;
    a {
        font-size: 25px;
        display: block;
        background-color: $pink;
        margin: 25px 0;
        color: #fff;
        text-decoration: none;
        border-radius: 10px;
        padding: 20px 0;
        font-weight: 700;
        text-transform: uppercase;
        transition: transform 0.15s cubic-bezier(0.17, 0.67, 0.29, 2.71) 0s;
        &:hover {
            transform: translate3d(0px, 0px, 0px) scale(1.015);
        }
    }
}

.socials {
    display: flex;
    justify-content: center;
    a {
        margin: 10px 5px;
    }
    svg {
        width: 40px;
        fill: $pink;
        transition: transform 0s cubic-bezier(0.23, 1, 0.32, 1);
        &:hover {
            transform: scale(1.075);
        }
    }
}

header {
    text-align: center;
    color: $pink;
    h1 {
        font-size: 36px;
    }
    .profile {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: #333;
        background-image: url('../public/profile-pic.webp');
        background-size: 70px;
        margin: 20px auto; 
    }
}